
import React, { useState, useEffect } from 'react';
import { useYouTubeData } from '../hooks/useYouTubeData';
import { toast } from "../components/ui/use-toast";
import { YoutubeIcon, InstagramIcon, FacebookIcon, TwitterIcon } from 'lucide-react';
import { GradientButton } from './ui/gradient-button';
import { useTranslation } from '@/contexts/TranslationContext';

const YouTubeSection: React.FC = () => {
  const { latestVideo, loading } = useYouTubeData(); // Error is now always null
  const [loaded, setLoaded] = useState(false);
  const [subscriberCount, setSubscriberCount] = useState<string | null>("10.7K"); // Updated default fallback value from 10.5K to 10.7K
  const { t } = useTranslation();
  
  useEffect(() => {
    // Only load YouTube API script once if not already present
    if (latestVideo && !document.getElementById('youtube-api-script')) {
      const script = document.createElement('script');
      script.id = 'youtube-api-script';
      script.src = 'https://apis.google.com/js/platform.js';
      script.async = true;
      script.defer = true; // Add defer for non-blocking loading
      document.body.appendChild(script);
      
      return () => {
        const existingScript = document.getElementById('youtube-api-script');
        if (existingScript) {
          document.body.removeChild(existingScript);
        }
      };
    }
  }, [latestVideo]);
  
  useEffect(() => {
    // Fetch subscriber count
    const fetchSubscriberCount = async () => {
      try {
        const channelId = 'UC7x_0NjD5ipVGBL5_36sdUQ';
        const apiKey = 'AIzaSyC3InJ6m9qBjfrN-5yYARv1LAyouGGw5-I';
        const url = `https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${channelId}&key=${apiKey}`;
        
        const response = await fetch(url);
        
        if (!response.ok) {
          console.error(`YouTube API error: ${response.status}`);
          // Keep using fallback value
          return;
        }
        
        const data = await response.json();
        
        if (data.items && data.items.length > 0) {
          const count = parseInt(data.items[0].statistics.subscriberCount, 10);
          // Format subscriber count (e.g., 1234 -> 1.2K)
          setSubscriberCount(formatSubscriberCount(count));
        }
      } catch (err) {
        console.error('Error fetching subscriber count:', err);
        // Silently fail without showing error to user - fallback value remains
      }
    };
    
    fetchSubscriberCount();
  }, []);
  
  // Helper function to format subscriber count
  const formatSubscriberCount = (count: number): string => {
    if (count >= 1000000) {
      return `${(count / 1000000).toFixed(1)}M`;
    } else if (count >= 1000) {
      return `${(count / 1000).toFixed(1)}K`;
    } else {
      return count.toString();
    }
  };
  
  return (
    <section className="py-10 bg-white w-full">
      <div className="container mx-auto px-4 flex justify-center">
        {/* Apply fixed width container with centered content */}
        <div className="w-full max-w-4xl">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            {/* YouTube Video */}
            <div className="glass rounded-lg shadow-lg overflow-hidden transition-all duration-300 transform hover:scale-[1.01] w-full">
              {loading ? (
                <div className="w-full aspect-video bg-gray-100 animate-pulse flex items-center justify-center">
                  <p className="text-gray-400">{t('youtube', 'loadingVideo', 'Loading latest video...')}</p>
                </div>
              ) : latestVideo ? (
                <div className={`relative w-full aspect-video ${!loaded ? 'bg-gray-100' : ''}`}>
                  <iframe
                    width="100%"
                    height="100%"
                    src={`https://www.youtube.com/embed/${latestVideo.id}?autoplay=0&rel=0`}
                    title={latestVideo.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="absolute inset-0 w-full h-full"
                    onLoad={() => setLoaded(true)}
                  ></iframe>
                </div>
              ) : (
                <div className="w-full aspect-video bg-gray-100 flex items-center justify-center">
                  <p className="text-gray-500">{t('youtube', 'loadingVideo', 'Loading video...')}</p>
                </div>
              )}
            </div>
            
            {/* Subscription Section */}
            <div className="flex flex-col items-start text-left w-full">
              <div className="inline-block px-3 py-1 rounded-full bg-gold-light/20 text-gold-dark text-xs font-medium mb-2">
                {t('youtube', 'neverMissABeat', 'Never Miss A Beat')}
              </div>
              <h2 className="text-3xl md:text-4xl font-display font-bold mb-3">
                {t('youtube', 'newBeatsWeekly', 'New beats weekly')}
              </h2>
              <p className="text-gray-700 mb-4 max-w-md">
                {t('youtube', 'subscribeDescription', 'Subscribe for new beats every Monday, Wednesday and Saturday + More. And make sure to never miss a beat; The highest quality of sound production realised by our international production team.')}
              </p>
              
              {/* YouTube Subscribe Button with Social Media Icons */}
              <div className="flex items-center space-x-3">
                <a 
                  href="https://www.youtube.com/channel/UC7x_0NjD5ipVGBL5_36sdUQ"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center px-4 py-2 rounded-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 transition-colors"
                  aria-label="Subscribe on YouTube"
                >
                  <YoutubeIcon className="w-5 h-5 mr-2" />
                  <span>{t('youtube', 'subscribe', 'Subscribe')}</span>
                  <span className="ml-2 bg-white bg-opacity-20 px-2 py-0.5 rounded-sm text-xs">
                    {subscriberCount}
                  </span>
                </a>
                
                {/* Social Media Icons */}
                <div className="flex items-center space-x-2">
                  <a 
                    href="https://www.youtube.com/@GRILLABEATS" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    aria-label="YouTube"
                    className="social-icon"
                  >
                    <YoutubeIcon className="h-5 w-5 text-black hover:text-gray-600 transition-colors" strokeWidth={1.5} />
                  </a>
                  
                  {/* Spotify Icon */}
                  <a 
                    href="https://open.spotify.com/artist/5eVDjSgNCvrI3EPspiNbYY" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    aria-label="Spotify"
                    className="social-icon"
                  >
                    <svg 
                      className="h-5 w-5 text-black hover:text-gray-600 transition-colors"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="12" r="10" />
                      <path d="M8 14.5c2.5-1 5.5-1 8 0" />
                      <path d="M8 11.5c3.5-1.5 8-1.5 11.5 0" />
                      <path d="M8 8.5c4.5-2 10.5-2 15 0" />
                    </svg>
                  </a>
                  
                  <a 
                    href="https://instagram.com/grillabeats" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    aria-label="Instagram"
                    className="social-icon"
                  >
                    <InstagramIcon className="h-5 w-5 text-black hover:text-gray-600 transition-colors" strokeWidth={1.5} />
                  </a>
                  
                  <a 
                    href="https://facebook.com/grillabeats.official" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                    className="social-icon"
                  >
                    <FacebookIcon className="h-5 w-5 text-black hover:text-gray-600 transition-colors" strokeWidth={1.5} />
                  </a>
                  
                  <a 
                    href="https://x.com/grillabeats" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    aria-label="X (Twitter)"
                    className="social-icon"
                  >
                    <TwitterIcon className="h-5 w-5 text-black hover:text-gray-600 transition-colors" strokeWidth={1.5} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default YouTubeSection;
