
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { 
  translationService, 
  changeLanguage as changeLanguageService, 
  initializeTranslations,
  getCurrentLanguageCode,
  Language
} from '@/utils/translationService';
import { supabase } from "@/integrations/supabase/client";
import { setupTranslations } from '@/utils/initTranslations';

// Define all website sections that need translations
export const ALL_TRANSLATION_SECTIONS = [
  'common', 
  'navigation', 
  'home', 
  'beats', 
  'pricing', 
  'support',
  'soundProduction', 
  'mixAndMaster',
  'vfx',
  'faq',
  'youtube',
  'howItWorks',
  'notFound',
  'aboutUs',
  'footer',
  'login',
  'registration',
  'profile',
  'cart',
  'checkout',
  'productDetails',
  'reviews',
  'legal',
  'contactForm',
  'settings',
  // Add any other sections used in the website
  'buttons',
  'errors',
  'forms',
  'headers',
  'labels',
  'messages',
  'placeholders',
  'seo',
  'social',
  'meta',
  'breadcrumbs'
];

type TranslationContextType = {
  t: (namespace: string, key: string, defaultValue?: string) => string;
  changeLanguage: (langCode: string) => Promise<void>;
  languages: Language[];
  currentLanguage: Language | null;
  ready: boolean;
};

const TranslationContext = createContext<TranslationContextType>({
  t: (namespace, key, defaultValue) => defaultValue || key,
  changeLanguage: async () => {},
  languages: [],
  currentLanguage: null,
  ready: false
});

export const useTranslation = () => useContext(TranslationContext);

export const TranslationProvider = ({ children }: { children: ReactNode }) => {
  const [languages, setLanguages] = useState<Language[]>([]);
  const [currentLanguage, setCurrentLanguage] = useState<Language | null>(null);
  const [ready, setReady] = useState(false);

  // Initialize translations
  useEffect(() => {
    const initialize = async () => {
      try {
        console.log("TranslationProvider: Initializing translation system");
        
        // Check local storage for saved language preference
        const savedLanguage = localStorage.getItem('preferredLanguage');
        console.log("TranslationProvider: Saved language from localStorage:", savedLanguage);
        
        // Set up translations system (initialize languages and base translations)
        await setupTranslations();
        
        // Try to initialize translations directly
        await initializeTranslations(savedLanguage || undefined);
        
        // Get loaded languages
        const langs = translationService.getLanguages();
        console.log("TranslationProvider: Available languages:", langs.length, langs);
        setLanguages(langs);
        
        // Get current language
        const current = translationService.getCurrentLanguage();
        console.log("TranslationProvider: Current language set to:", current?.name, current);
        setCurrentLanguage(current);
        
        // Set up translations for all languages using edge function
        try {
          console.log("TranslationProvider: Setting up translations via edge function");
          
          const response = await supabase.functions.invoke('add-translations', {
            method: 'POST',
            body: { 
              force: true,  // Force update all translations to ensure complete coverage
              sections: ALL_TRANSLATION_SECTIONS
            },
          });
          
          if (response.error) {
            throw new Error(`Function error: ${response.error.message}`);
          }
          
          console.log("TranslationProvider: Successfully added translations:", response.data);
          
          // Reload translations for current language
          if (current) {
            await changeLanguageService(current.code);
          }
        } catch (error) {
          console.error("TranslationProvider: Error adding translations via edge function:", error);
          
          // Fallback: try direct HTTP call to our edge function
          try {
            console.log("TranslationProvider: Trying direct HTTP call to edge function");
            
            const response = await fetch('https://dwxhsyqcytpjmnoohtpj.supabase.co/functions/v1/add-translations', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImR3eGhzeXFjeXRwam1ub29odHBqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDAwMjgwOTIsImV4cCI6MjA1NTYwNDA5Mn0.4vYK8WlmrQnL9OIu26QnXlw_eagQFICjPAOsPbm12M4`
              },
              body: JSON.stringify({ 
                force: true,  // Force update all translations
                sections: ALL_TRANSLATION_SECTIONS
              })
            });
            
            if (!response.ok) {
              throw new Error(`HTTP error: ${response.status}`);
            }
            
            const result = await response.json();
            console.log("TranslationProvider: Direct HTTP call result:", result);
            
            // Reload translations for current language
            if (current) {
              await changeLanguageService(current.code);
            }
          } catch (fallbackError) {
            console.error("TranslationProvider: Both methods failed to add translations:", fallbackError);
            console.error("Could not load all translations. Some text may appear in English.");
          }
        }
        
        setReady(true);
        console.log("Translations loaded successfully");
      } catch (error) {
        console.error("Failed to initialize translation system:", error);
        console.error("Failed to initialize translations. Using fallbacks.");
        setReady(true); // Set ready even on error so the app doesn't get stuck
      }
    };

    initialize();
  }, []);

  // Listen for language changes
  useEffect(() => {
    const handleLanguageChange = () => {
      const current = translationService.getCurrentLanguage();
      setCurrentLanguage(current);
      console.log("TranslationProvider: Language changed to:", current?.name);
    };
    
    window.addEventListener('languagechange', handleLanguageChange);
    
    return () => {
      window.removeEventListener('languagechange', handleLanguageChange);
    };
  }, []);

  // Translation function
  const t = (namespace: string, key: string, defaultValue?: string): string => {
    const translation = translationService.getTranslation(namespace, key, defaultValue);
    return translation || defaultValue || key;
  };

  // Change language function with page reload for full refresh
  const changeLanguageFn = async (langCode: string): Promise<void> => {
    console.log("TranslationContext: Changing language to:", langCode);
    try {
      // Save preference to localStorage first
      localStorage.setItem('preferredLanguage', langCode);
      
      await changeLanguageService(langCode);
      
      // Set current language
      const current = translationService.getCurrentLanguage();
      setCurrentLanguage(current);
      
      // Force a re-render of all components
      window.dispatchEvent(new Event('languagechange'));
      
      console.log(`Language changed to ${current?.name || langCode}. Reloading...`);
      
      // Reload the page immediately to ensure complete site translation
      setTimeout(() => {
        window.location.reload();
      }, 500); // Reduced to 500ms for faster reload
    } catch (err) {
      console.error("TranslationContext: Failed to change language:", err);
      console.error("Failed to change language");
      throw err;
    }
  };

  return (
    <TranslationContext.Provider 
      value={{ 
        t, 
        changeLanguage: changeLanguageFn, 
        languages, 
        currentLanguage,
        ready 
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};
