// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://dwxhsyqcytpjmnoohtpj.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImR3eGhzeXFjeXRwam1ub29odHBqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDAwMjgwOTIsImV4cCI6MjA1NTYwNDA5Mn0.4vYK8WlmrQnL9OIu26QnXlw_eagQFICjPAOsPbm12M4";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);