
import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { StarBorder } from "@/components/ui/star-border";
import { useTranslation } from '@/contexts/TranslationContext';

const FAQ = () => {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [expandedItem, setExpandedItem] = useState<string | null>(null);
  const { t } = useTranslation();
  const [faqItems, setFaqItems] = useState<any[]>([]);
  
  // Update FAQ items when translations change or language changes
  useEffect(() => {
    const handleLanguageChange = () => {
      // Update FAQ items with new translations
      setFaqItems([
        {
          id: "item-1",
          question: t('faq', 'audioFormatsQuestion', 'What audio formats do you provide?'),
          answer: t('faq', 'audioFormatsAnswer', 'We provide high-quality MP3 (320kbps) and WAV files for all beats. Premium licenses also include individual track stems.')
        },
        {
          id: "item-2",
          question: t('faq', 'streamingQuestion', 'Can I use your beats on streaming platforms?'),
          answer: t('faq', 'streamingAnswer', 'Yes! Our licenses allow you to distribute your music on all major streaming platforms like Spotify, Apple Music, YouTube, and more.')
        },
        {
          id: "item-3",
          question: t('faq', 'purchaseQuestion', 'What happens after I purchase a beat?'),
          answer: t('faq', 'purchaseAnswer', 'You\'ll receive an instant download link for your files and a license agreement via email. Premium licenses include additional files and support.')
        },
        {
          id: "item-4",
          question: t('faq', 'customBeatQuestion', 'Do you offer custom beat production?'),
          answer: t('faq', 'customBeatAnswer', 'Yes, we offer custom beat production services. Contact us directly to discuss your specific requirements and timeline.')
        },
        {
          id: "item-5",
          question: t('faq', 'studioSessionQuestion', 'Can I book a studio session with GRILLABEATS?'),
          answer: t('faq', 'studioSessionAnswer', 'GRILLABEATS hands a policy which is based on "Invitation Only" as far as studio sessions with our producers. Invitations are being made occasionally, when we believe your potential matches our vision. Or if we believe your physical presence can help improve your final production, mixing or mastering session.')
        }
      ]);
    };
    
    // Initialize FAQ items
    handleLanguageChange();
    
    // Listen for language changes
    window.addEventListener('languagechange', handleLanguageChange);
    
    return () => {
      window.removeEventListener('languagechange', handleLanguageChange);
    };
  }, [t]);

  const handleMouseEnter = (itemId: string) => {
    setHoveredItem(itemId);
    setExpandedItem(itemId); // Auto-expand on hover
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
    // Don't collapse on mouse leave to keep selected item expanded
  };

  const handleValueChange = (value: string) => {
    setExpandedItem(value === "" ? null : value);
  };

  return (
    <div className="w-full max-w-3xl mx-auto py-4">
      <h2 className="text-2xl font-bold text-center mb-4">{t('faq', 'gotTheAnswers', 'We got the answers')}</h2>
      <Accordion 
        type="single" 
        collapsible 
        className="space-y-4"
        value={expandedItem || undefined}
        onValueChange={handleValueChange}
      >
        {faqItems.map((item) => {
          const isActive = hoveredItem === item.id || expandedItem === item.id;
          
          return (
            <div
              key={item.id}
              onMouseEnter={() => handleMouseEnter(item.id)}
              onMouseLeave={handleMouseLeave}
              className="transition-all duration-300"
            >
              {isActive ? (
                <StarBorder
                  as="div"
                  color="#E5A530"
                  className="w-full"
                >
                  <AccordionItem value={item.id} className="border-0 overflow-hidden bg-white">
                    <AccordionTrigger className="px-4 py-3 text-sm font-medium hover:text-gold-dark transition-colors text-left">
                      {item.question}
                    </AccordionTrigger>
                    <AccordionContent className="px-4 pb-3 text-sm text-left">
                      {item.answer}
                    </AccordionContent>
                  </AccordionItem>
                </StarBorder>
              ) : (
                <AccordionItem value={item.id} className="border-2 border-black rounded-lg overflow-hidden">
                  <AccordionTrigger className="px-4 py-3 text-sm font-medium hover:text-gold-dark transition-colors text-left">
                    {item.question}
                  </AccordionTrigger>
                  <AccordionContent className="px-4 pb-3 text-sm text-left">
                    {item.answer}
                  </AccordionContent>
                </AccordionItem>
              )}
            </div>
          );
        })}
      </Accordion>
    </div>
  );
};

export default FAQ;
