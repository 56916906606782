import React, { useState, useEffect } from 'react';
import { Menu, X, Globe } from 'lucide-react';
import { useOnScrollUp } from '../hooks/useOnScrollUp';
import { Link, useLocation } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useTranslation } from '@/contexts/TranslationContext';
import { ALL_TRANSLATION_SECTIONS } from './TranslationProvider';

interface NavigationProps {
  className?: string;
  dropdownBgClass?: string;
}

const Navigation: React.FC<NavigationProps> = ({ 
  className, 
  dropdownBgClass = "glass"
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isVisible = useOnScrollUp();
  const location = useLocation();
  const { t, changeLanguage, currentLanguage, languages } = useTranslation();
  
  useEffect(() => {
    if (languages.length > 0 && currentLanguage) {
      console.log("Navigation: Available languages:", languages.length);
      console.log("Navigation: Current language:", currentLanguage.code, currentLanguage.name);
    }
  }, [languages, currentLanguage]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Function to handle smooth scrolling to Beats section
  const scrollToBeats = (e: React.MouseEvent) => {
    if (location.pathname === '/') {
      e.preventDefault();
      const beatsSection = document.getElementById('beats');
      if (beatsSection) {
        beatsSection.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }
      // Close mobile menu if it's open
      if (isMenuOpen) {
        setIsMenuOpen(false);
      }
    }
  };

  // Handle language selection with improved matching
  const selectLanguage = async (lang: { code: string; language: string; country: string; country_code: string }) => {
    console.log("Navigation: Selected language:", lang);
    
    try {
      // Find the matching language from our database languages
      const dbLanguage = languages.find(l => 
        // First try an exact match on language code
        l.code === lang.code ||
        // Try match on country code
        l.country_code === lang.country_code ||
        // For cases like 'en' vs 'en-US'
        lang.code === l.code.split('-')[0] ||
        l.code.startsWith(lang.code)
      );
      
      if (dbLanguage) {
        console.log("Navigation: Found matching DB language:", dbLanguage);
        await changeLanguage(dbLanguage.code);
      } else {
        // If no match, try to use the language code directly
        console.log("Navigation: No exact match found. Trying with language code:", lang.code);
        await changeLanguage(lang.code);
      }
    } catch (err) {
      console.error("Navigation: Error changing language:", err);
    }
    
    // Force a re-render by setting the menu state
    setIsMenuOpen(false);
  };

  // Complete, organized list of languages with correct ISO codes
  const languagesList = [
    { country: "United States", code: "en", language: "English", country_code: "us" },
    { country: "China", code: "zh", language: "中文", country_code: "cn" },
    { country: "Spain", code: "es", language: "Español", country_code: "es" },
    { country: "United Kingdom", code: "en-GB", language: "English (UK)", country_code: "gb" },
    { country: "India", code: "hi", language: "हिन्दी", country_code: "in" },
    { country: "France", code: "fr", language: "Français", country_code: "fr" },
    { country: "Japan", code: "ja", language: "日本語", country_code: "jp" },
    { country: "Russia", code: "ru", language: "Русский", country_code: "ru" },
    { country: "Germany", code: "de", language: "Deutsch", country_code: "de" },
    { country: "Brazil", code: "pt-BR", language: "Português", country_code: "br" },
    { country: "Mexico", code: "es-MX", language: "Español", country_code: "mx" },
    { country: "Argentina", code: "es-AR", language: "Español", country_code: "ar" },
    { country: "Saudi Arabia", code: "ar", language: "العربية", country_code: "sa" },
    { country: "Iraq", code: "ar-IQ", language: "العربية", country_code: "iq" },
    { country: "Ukraine", code: "uk", language: "Українська", country_code: "ua" },
    { country: "Poland", code: "pl", language: "Polski", country_code: "pl" },
    { country: "Türkiye", code: "tr", language: "Türkçe", country_code: "tr" },
    { country: "Thailand", code: "th", language: "ไทย", country_code: "th" },
    { country: "Indonesia", code: "id", language: "Bahasa Indonesia", country_code: "id" },
    { country: "Vietnam", code: "vi", language: "Tiếng Việt", country_code: "vn" },
    { country: "Canada", code: "fr-CA", language: "Français", country_code: "ca" },
    { country: "Cambodia", code: "km", language: "ភាសាខ្មែរ", country_code: "kh" },
    { country: "Kazakhstan", code: "kk", language: "Қазақша", country_code: "kz" },
    { country: "Uzbekistan", code: "uz", language: "O'zbek", country_code: "uz" },
  ];

  // Find currently selected language in the list
  const currentLangCode = currentLanguage?.country_code || 'us';
  const currentDisplayLang = languagesList.find(l => l.country_code === currentLangCode) || languagesList[0];

  // Get flag URL with correct country code
  const getFlagUrl = (countryCode: string) => {
    return `https://flagcdn.com/20x15/${countryCode.toLowerCase()}.png`;
  };

  // Get translated content for navigation items
  const beatsText = t('navigation', 'beats', 'Beats');
  const solutionsText = t('navigation', 'solutions', 'Solutions');
  const soundProductionText = t('navigation', 'soundProduction', 'Sound Production');
  const mixAndMasterText = t('navigation', 'mixAndMaster', 'Mix & Master');
  const vfxText = t('navigation', 'vfx', 'SFX');
  const supportText = t('navigation', 'support', 'Support');
  const languageText = t('navigation', 'language', 'Language');

  // Update document language when current language changes
  useEffect(() => {
    if (currentLanguage) {
      document.documentElement.lang = currentLanguage.code;
      document.title = t('meta', 'siteTitle', 'BEATS - Premium Beats & Sound Production');
      console.log(`Document language set to: ${currentLanguage.code}`);
    }
  }, [currentLanguage, t]);

  return (
    <nav 
      className={`fixed top-0 left-0 w-full z-50 glass transition-opacity duration-300 -mt-3 ${
        isVisible ? 'opacity-100' : 'opacity-0'
      } ${className}`}
    >
      <div className="container mx-auto px-6">
        <div className="py-0 flex justify-between items-center">
          <a href="/" className="flex items-center">
            <img src="/lovable-uploads/a12b43ca-3e69-4e2b-ab2e-489603798a23.png" alt="BEATS Logo" className="h-28 w-auto" />
          </a>

          {/* Mobile menu button */}
          <button 
            onClick={toggleMenu} 
            className="lg:hidden rounded-full p-2 focus:outline-none focus:ring-2 focus:ring-gold transition-colors"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>

          {/* Desktop menu with language dropdown */}
          <div className="hidden lg:flex items-center space-x-5">
            {location.pathname === '/' ? (
              <a 
                href="#beats" 
                onClick={scrollToBeats} 
                className="text-sm hover:text-gold-dark transition-colors cursor-pointer"
              >
                {beatsText}
              </a>
            ) : (
              <Link to="/" className="text-sm hover:text-gold-dark transition-colors">{beatsText}</Link>
            )}
            <div className="relative group">
              <a href="#solutions" className="text-sm hover:text-gold-dark transition-colors">{solutionsText}</a>
              <div className={`absolute left-0 mt-2 w-48 rounded-md shadow-lg ${dropdownBgClass} invisible opacity-0 group-hover:visible group-hover:opacity-100 transition-all duration-300`}>
                <div className="py-2">
                  <Link to="/sound-production" className="block px-4 py-2 text-sm hover:bg-white/20 transition-colors">{soundProductionText}</Link>
                  <Link to="/mix-and-master" className="block px-4 py-2 text-sm hover:bg-white/20 transition-colors">{mixAndMasterText}</Link>
                  <Link to="/vfx" className="block px-4 py-2 text-sm hover:bg-white/20 transition-colors">{vfxText}</Link>
                </div>
              </div>
            </div>
            <Link to="/support" className="text-sm hover:text-gold-dark transition-colors">{supportText}</Link>
            
            {/* Language dropdown */}
            <DropdownMenu>
              <DropdownMenuTrigger className="flex items-center hover:text-gold-dark transition-colors focus:outline-none">
                <Globe size={16} className="mr-1" />
                {currentDisplayLang && (
                  <span className="text-xs flex items-center gap-1">
                    <img 
                      src={getFlagUrl(currentDisplayLang.country_code)}
                      alt={`${currentDisplayLang.country} flag`} 
                      width="16" 
                      height="12"
                      className="inline-block"
                    />
                    {currentDisplayLang.language}
                  </span>
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent className="max-h-60 overflow-y-auto" align="end">
                {languagesList.map((lang) => (
                  <DropdownMenuItem 
                    key={lang.code}
                    className="flex items-center space-x-2 cursor-pointer text-xs py-1"
                    onClick={() => selectLanguage(lang)}
                  >
                    <img 
                      src={getFlagUrl(lang.country_code)}
                      alt={`${lang.country} flag`} 
                      width="20" 
                      height="15"
                      className="inline-block"
                    />
                    <span>{lang.language}</span>
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>

        {/* Mobile menu with language selection */}
        <div className={`lg:hidden absolute w-full left-0 rounded-b-lg shadow-lg bg-white transition-all duration-300 ease-in-out ${
          isMenuOpen ? 'max-h-screen opacity-100 visible' : 'max-h-0 opacity-0 invisible'
        } overflow-hidden`}>
          <div className="px-4 py-2 space-y-3">
            {location.pathname === '/' ? (
              <a 
                href="#beats" 
                onClick={scrollToBeats}
                className="block py-2 text-sm"
              >
                {beatsText}
              </a>
            ) : (
              <Link to="/" className="block py-2 text-sm">{beatsText}</Link>
            )}
            <div className="space-y-1 ml-4">
              <p className="font-semibold text-sm">{solutionsText}</p>
              <Link to="/sound-production" className="block py-1 text-xs">{soundProductionText}</Link>
              <Link to="/mix-and-master" className="block py-1 text-xs">{mixAndMasterText}</Link>
              <Link to="/vfx" className="block py-1 text-xs">{vfxText}</Link>
            </div>
            <Link to="/support" className="block py-2 text-sm">{supportText}</Link>
            
            {/* Mobile language selection */}
            <div className="space-y-1">
              <p className="font-semibold text-sm flex items-center">
                <Globe size={14} className="mr-1" />
                {languageText}
              </p>
              <div className="ml-4 max-h-40 overflow-y-auto">
                {languagesList.map((lang) => (
                  <button
                    key={lang.code}
                    className="flex items-center space-x-2 py-1 w-full text-left text-xs"
                    onClick={() => selectLanguage(lang)}
                  >
                    <img 
                      src={getFlagUrl(lang.country_code)}
                      alt={`${lang.country} flag`} 
                      width="16" 
                      height="12"
                      className="inline-block"
                    />
                    <span>{lang.language}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
